import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import AxiosMockAdapter from "axios-mock-adapter";
import jwtDecode, { JwtPayload } from "jwt-decode";
import posthog from "posthog-js";
import { getAccessToken, setAccessToken } from "utils/auth";

import { publicRouteObject } from "./routes";

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
});

const mockApi = new AxiosMockAdapter(api, {
  onNoMatch: "passthrough",
  delayResponse: 1000,
});

/*
 *
 * Creates a singleton used to make and return one request for the auth0 token.
 *
 * If the stored token has not expired return that, otherwise initiate request to auth0.
 *
 * When the token expires and multiple calls are made in a short timeframe,
 * all of them will need to request a new token.
 *
 * Here we fire the first request and all subsequent calls will receive
 * the pending promise until it resolves
 *
 */

export function getTokenExpirationTime(): number | null {
  const accessToken = getAccessToken();
  if (!accessToken) return null;

  try {
    const decodedToken = jwtDecode<JwtPayload>(accessToken);
    if (!decodedToken || typeof decodedToken !== "object") return null;

    const expirationTime = decodedToken.exp;
    if (typeof expirationTime !== "number") return null;

    // Return the expiration time in milliseconds
    return expirationTime * 1000;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error decoding JWT:", error);
    return null;
  }
}

export const tokenRefresher = (() => {
  const accessToken = getAccessToken();
  let promise: Promise<any> | undefined;

  return {
    fetch: async (): Promise<string> => {
      if (promise) return promise;

      if (accessToken) {
        const now = new Date().getTime();
        const parsedToken = jwtDecode(accessToken) as { exp: number };
        const seconds = (parsedToken?.exp || Infinity) - now / 1000;
        if (seconds > 0) {
          return accessToken;
        }
      }

      promise = api.prototype
        .getAccessTokenSilently({
          audience: `${process.env.NEXT_PUBLIC_API_BASE_URL}/`,
          scope: "users",
        })
        .then((auth0Token: string) => {
          promise = undefined;
          setAccessToken(auth0Token);
          return auth0Token;
        })
        .catch(api.prototype.logout);

      return promise;
    },
  };
})();

api.interceptors.request.use((req) => req);

const errorInterceptor = (error: AxiosError): Promise<AxiosError> => {
  if (error.response?.status === 403) {
    if (process.env.NEXT_PUBLIC_POSTHOG_KEY) {
      posthog.capture("errorInterceptor: STATUS 403", {
        error: JSON.stringify(error),
      });
    }
    api.prototype?.logout?.({ returnTo: `${window.location.origin}/login` });
  }
  return Promise.reject(error);
};

const responseInterceptor = (response: AxiosResponse): Promise<AxiosResponse> =>
  response.data.response;

const requestInterceptor = async (
  config: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
  const token =
    config.url && publicRouteObject[config.url]
      ? ""
      : await tokenRefresher.fetch();

  const conf = {
    ...config,
    ...(token ? { headers: { Authorization: `Bearer ${token}` } } : {}),
  };

  return conf;
};

api.interceptors.request.use(requestInterceptor, errorInterceptor);
api.interceptors.response.use(responseInterceptor, errorInterceptor);

export { api, mockApi };
