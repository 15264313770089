import React, { useMemo } from "react";

import { Box, Button, Skeleton, Icon } from "@chakra-ui/react";
import { OnChangeFn, PaginationState, Table } from "@tanstack/react-table";
import {
  BsChevronLeft,
  BsChevronRight,
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
} from "react-icons/bs";

const noop = () => {};

interface Props {
  isLoading?: boolean;
  table: Table<any>;
  pageIndex?: number;
  pageCount: number;
  pageSize?: number;
  totalCount?: number;
  onPaginationChange?: OnChangeFn<PaginationState>;
}

export function Pagination({
  isLoading = false,
  table,
  pageCount,
  pageIndex = 0,
  pageSize = 10,
  totalCount,
  onPaginationChange = noop,
}: Props) {
  const isDisabled = table.getRowModel().rows.length === 0;
  const currentRange = useMemo(
    () => ({
      from: totalCount && totalCount > 0 ? pageIndex * pageSize + 1 : 0,
      to: Math.min(
        (pageIndex + 1) * pageSize,
        totalCount ?? Number.POSITIVE_INFINITY,
      ),
    }),
    [pageIndex, pageSize, totalCount],
  );
  const range = {
    from: currentRange.from,
    to: currentRange.to,
    total: totalCount,
  };
  const handleFirstPageClick = () => {
    if (table.getCanPreviousPage() && pageIndex > 0) {
      onPaginationChange({
        pageIndex: 0,
        pageSize,
      });
    }
  };

  const handlePreviousPageClick = () => {
    if (table.getCanPreviousPage()) {
      table.previousPage();
    }
  };

  const handleNextPageClick = () => {
    if (table.getCanNextPage()) {
      table.nextPage();
    }
  };

  const handleLastPageClick = () => {
    if (table.getCanNextPage() && pageIndex < pageCount - 1) {
      onPaginationChange({
        pageIndex: pageCount - 1,
        pageSize,
      });
    }
  };
  return (
    <Box display="flex" gap={2} alignItems="center" px={6}>
      {isLoading ? (
        <Skeleton
          height="16px"
          width="64px"
          borderRadius="2xl"
          endColor="gray.300"
          mb={1}
        />
      ) : (
        <Box mr={4} fontSize={15} color="gray.700">
          {range.from}-{range.to} of {range.total}
        </Box>
      )}

      <Button
        p={0}
        size="sm"
        background="transparent"
        onClick={handleFirstPageClick}
        isDisabled={isLoading || isDisabled || !table.getCanPreviousPage()}
        _disabled={{
          opacity: 0.3,
        }}
        _hover={{
          background: "gray.100",
        }}
      >
        <Icon as={BsChevronDoubleLeft} w="18px" h="18px" color="gray.700" />
      </Button>
      <Button
        p={0}
        size="sm"
        background="transparent"
        onClick={handlePreviousPageClick}
        isDisabled={isLoading || isDisabled || !table.getCanPreviousPage()}
        _disabled={{
          opacity: 0.3,
        }}
        _hover={{
          background: "gray.100",
        }}
      >
        <Icon as={BsChevronLeft} w="18px" h="18px" color="gray.700" />
      </Button>
      <Button
        p={0}
        size="sm"
        background="transparent"
        onClick={handleNextPageClick}
        isDisabled={isLoading || isDisabled || !table.getCanNextPage()}
        _disabled={{
          opacity: 0.3,
        }}
        _hover={{
          background: "gray.100",
        }}
      >
        <Icon as={BsChevronRight} w="18px" h="18px" color="gray.700" />
      </Button>
      <Button
        p={0}
        size="sm"
        background="transparent"
        onClick={handleLastPageClick}
        isDisabled={isLoading || isDisabled || !table.getCanNextPage()}
        _disabled={{
          opacity: 0.3,
        }}
        _hover={{
          background: "gray.100",
        }}
      >
        <Icon as={BsChevronDoubleRight} w="18px" h="18px" color="gray.700" />
      </Button>
    </Box>
  );
}
