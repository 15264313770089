/* eslint-disable react/prop-types */
import React, { ReactNode } from "react";

import { Box, ChakraComponent, Flex, Heading } from "@chakra-ui/react";

// @ts-ignore
export const Card: ChakraComponent<
  "div",
  { heading?: ReactNode; topRight?: ReactNode }
> = ({ children, heading, topRight, ...rest }) => (
  <Box
    className="ui-card"
    p={5}
    bg="white"
    w="100%"
    border="1px solid"
    borderWidth={0}
    borderColor="light_gray.300"
    borderRadius="16px"
    boxShadow="0px 0px 0px 1px rgba(14, 63, 126, 0.04), 
                 0px 1px 1px -0.5px rgba(42, 51, 69, 0.04), 
                 0px 3px 3px -1.5px rgba(42, 51, 70, 0.04), 
                 0px 6px 6px -3px rgba(42, 51, 70, 0.04), 
                 0px 12px 12px -6px rgba(14, 63, 126, 0.04), 
                 0px 24px 24px -12px rgba(14, 63, 126, 0.04)"
    {...rest}
  >
    {(heading || topRight) && (
      <Flex justifyContent="space-between">
        {typeof heading === "string" ? (
          <Heading fontWeight="semibold" fontSize="xl">
            {heading}
          </Heading>
        ) : (
          heading
        )}
        {topRight}
      </Flex>
    )}

    {children}
  </Box>
);
