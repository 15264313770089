import React, { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Heading } from "@chakra-ui/react";
import { useGetUserPermissions } from "network/User/get";
import Image from "next/image";
import Link from "next/link";
import { getFirstAuthorizedRoute } from "utils/determineNextPath";

interface HostFiLogoProps {
  width?: string;
  height?: string;
  mode?: "dark" | "white";
  mt?: number | undefined;
}

export function HostFiLogo({
  width = "100%",
  height = "100%",
  mode = "dark",
  mt = 2,
}: HostFiLogoProps) {
  const { data: permissions } = useGetUserPermissions();
  const { isAuthenticated = false } = useAuth0();
  const [href, setHref] = useState<string>("/");

  useEffect(() => {
    if (isAuthenticated && permissions)
      setHref(getFirstAuthorizedRoute(permissions));
  }, [isAuthenticated, permissions]);

  return (
    <Heading as="h1" mr={5} mt={mt}>
      <Link href={href} passHref>
        <a href={href} style={{ display: "flex" }}>
          <Image
            src={`/images/accountable-logo-${mode}.svg`}
            alt="Accountable"
            width={width}
            height={height}
          />
        </a>
      </Link>
    </Heading>
  );
}
