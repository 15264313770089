import React from "react";

import { Box, Spinner } from "@chakra-ui/react";
import Image from "next/image";

export function CustomSpinner() {
  return (
    <Box width="40px" height="40px" position="relative" display="inline-block">
      <Spinner
        thickness="5px"
        speed="0.75s"
        emptyColor="gray.300"
        color="midnight_teal.400"
        width="40px"
        height="40px"
      />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src="/images/accountable-spinner.svg"
          alt="Accountable"
          width="15.6px"
          height="15.6px"
        />
      </Box>
    </Box>
  );
}
