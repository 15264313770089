import React from "react";

import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";

interface Props {
  searchPlaceholder?: string;
  handleOnSearchInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchFilter?: string;
  handleClearClick: () => void;
}

export function Search({
  searchPlaceholder = "Search",
  handleOnSearchInputChange,
  searchFilter = "",
  handleClearClick,
}: Props) {
  return (
    <InputGroup size="lg" w="350px">
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.400" bgSize="16px" />
      </InputLeftElement>
      <Input
        placeholder={searchPlaceholder || "Search"}
        onChange={(e) => handleOnSearchInputChange(e)}
        value={searchFilter}
        sx={{
          borderColor: "rgba(210, 216, 224, 0.5)",
          borderRadius: "61px",
          fontSize: "sm",
          backgroundColor: "white",
          "::placeholder": {
            color: "gray.400",
          },
        }}
      />
      {searchFilter?.length > 0 && (
        <InputRightElement>
          <IconButton
            variant="ghost"
            aria-label="clear"
            icon={<CloseIcon color="gray.400" bgSize="16px" />}
            // h="1.75rem"
            size="sm"
            onClick={handleClearClick}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
}
