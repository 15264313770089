import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  GroupingState,
  Table,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Pagination } from "components/TableComponent/Pagination";
import { Search } from "components/TableComponent/Search";

export interface TableConfiguration {
  tableWidth?: string;
  defaultPageSize?: number;
  initialGroupingState?: GroupingState;
  hasPagination?: boolean;
  hasSearch?: boolean;
  columns: Array<any>;
  searchPlaceholder?: string;
  query?: any;
  data: Array<any>;
  isLoading?: boolean;
}

const defaultTableConfigurationState = {
  tableWidth: "100%",
  defaultPageSize: 10,
  initialGroupingState: [],
  hasPagination: false,
  hasSearch: false,
  columns: [],
  searchPlaceholder: "",
  data: [],
};
interface TableConfigContext {
  setTableConfiguration: (props: TableConfiguration) => void;
  table?: Table<any>;
  paginationComponent: ReactNode;
  searchComponent: ReactNode;
  pageIndex: number;
  pageSize: number;
  searchFilter: string;
  resetPagination: () => void;
}

const defaultTableConfigContext = {
  setTableConfiguration: () => {},
  paginationComponent: null,
  searchComponent: null,
  searchFilter: "",
  pageIndex: 0,
  pageSize: 10,
  resetPagination: () => {},
};

const TableContext = createContext<TableConfigContext>(
  defaultTableConfigContext,
);

export const useTableContext = () => useContext(TableContext);

export const TableProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<TableConfiguration>(
    defaultTableConfigurationState,
  );
  const [searchFilter, setSearchFilter] = useState("");
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: state.defaultPageSize ?? 10,
  });

  const resetPagination = useCallback(() => {
    setPagination({
      pageIndex: 0,
      pageSize: state.defaultPageSize ?? 10,
    });
  }, [state.defaultPageSize]);

  const handleSearchChange = useCallback(
    (searchTerm: string) => {
      setSearchFilter(searchTerm);
      resetPagination();
    },
    [resetPagination],
  );

  // Only refetch when searchFilter is cleared to an empty string
  useEffect(() => {
    if (searchFilter === "") {
      state.query?.refetch();
    }
  }, [searchFilter]);

  const totalCount = state.query?.data?.total_count;
  const pageCount = useMemo(() => {
    if (!totalCount) {
      return -1;
    }

    return typeof totalCount === "number"
      ? Math.ceil(totalCount / pageSize)
      : -1;
  }, [totalCount, pageSize]);

  const hasPagination = true;
  const hasSearch = true;

  const filtersState = hasSearch
    ? {
        search: searchFilter,
      }
    : {};

  const commonConfiguration = {
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    // onGroupingChange: setGrouping,
    state: {
      grouping: state?.initialGroupingState ?? [],
    },
    initialState: {
      expanded: true as true,
    },
    autoResetExpanded: false,
  };

  const tableConfig = hasPagination
    ? {
        ...commonConfiguration,
        data: state?.isLoading ? Array(pageSize).fill({}) : state?.data,
        columns: state.columns,
        pageCount,
        state: {
          ...commonConfiguration.state,
          pagination: {
            pageIndex,
            pageSize,
          },
          globalFilter: { ...filtersState },
        },
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
      }
    : {
        ...commonConfiguration,
        data: state?.data,
        columns: state.columns,
        state: {
          ...commonConfiguration.state,
          globalFilter: { ...filtersState },
        },
        getCoreRowModel: getCoreRowModel(),
      };

  const table = useReactTable<any>(tableConfig);

  const paginationComponent = state.hasPagination ? (
    <Pagination
      isLoading={state.query?.isFetching}
      table={table}
      pageIndex={pageIndex}
      pageCount={pageCount}
      totalCount={totalCount}
      pageSize={pageSize}
      onPaginationChange={setPagination}
    />
  ) : null;

  const handleClearClick = () => {
    handleSearchChange("");
    resetPagination();
  };

  const handleOnSearchInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target;
    handleSearchChange(value);
    resetPagination();
  };
  const searchComponent = state.hasSearch ? (
    <Search
      handleClearClick={handleClearClick}
      searchFilter={searchFilter}
      handleOnSearchInputChange={handleOnSearchInputChange}
      searchPlaceholder={state.searchPlaceholder}
    />
  ) : null;

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue: TableConfigContext = {
    setTableConfiguration: setState,
    table,
    paginationComponent,
    searchComponent,
    pageIndex,
    pageSize,
    searchFilter,
    resetPagination,
  };

  return (
    <TableContext.Provider value={contextValue}>
      {children}
    </TableContext.Provider>
  );
};
