import { StyleFunctionProps, theme as defaultTheme } from "@chakra-ui/react";
import merge from "lodash/merge";

const theme = merge({}, defaultTheme, {
  styles: {
    global: {
      "html, body": {
        fontSize: "14px",
        color: "gray.900",
      },
      "h1, h2, h3, h4, h5, h6": {
        fontWeight: "normal",
      },
    },
  },
  colors: {
    midnight_teal: {
      100: "#4D7070",
      200: "#335B5B",
      300: "#1A4747",
      400: "#013333",
      500: "#002D2D",
    },
    emerald_mint: {
      30: "rgba(165, 240, 214, 0.3)",
      50: "rgba(165, 240, 214, 0.5)",
      100: "rgba(165, 240, 214, 1)",
      200: "rgba(120, 232, 194, 1)",
      300: "rgba(75, 225, 173, 1)",
      400: "rgba(30, 217, 153, 1)",
      500: "rgba(27, 195, 138, 1)",
    },
    acc_blue: {
      100: "#67B5D9",
      200: "#1E9ED9",
      300: "#1E63D9",
      400: "#1E28D9",
    },
    light_gray: {
      100: "#FBFBFC",
      150: "#F6F7FB",
      200: "#F1F2F9",
      250: "#E9ECF3",
      300: "#EFEFFD",
      400: "#E1E5ED",
    },
    gray: {
      50: "#F7FAFC",
      100: "#F9F9F9",
      200: "#EAEAEA",
      300: "#CBD5E0",
      400: "#828282",
      500: "#676879",
      600: "#4A5568",
      700: "#2D3748",
      800: "#1A202C",
      900: "#171923",
    },
    green: {
      200: "#DDF1EC",
      400: "#1EA382",
      default: "#1EA382",
    },
    red: {
      200: "#F8DCDC",
      400: "#DC5151",
      default: "#DC5151",
    },
    yellow: {
      200: "#F8EEDA",
      500: "rgba(214, 158, 46, 0.89)",
    },
  },
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  fontSizes: {
    "2xs": "10px",
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "28px",
    "4xl": "32px",
    "5xl": "48px",
    "6xl": "64px",
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    semibold: 600,
  },
  components: {
    Input: {
      defaultProps: {
        errorBorderColor: "gray.200",
        focusBorderColor: "gray.200",
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: "normal",
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "medium",
      },
      variants: {
        solid: () => ({
          bg: "midnight_teal.400",
          color: "white",
          fontWeight: "medium",
          fontSize: "sm",
          _active: {
            bg: "emerald_mint.500",
          },
          _disabled: {
            _disabled: {
              _hover: {
                bg: "midnight_teal.400",
              },
            },
          },
        }),
        outline: (props: StyleFunctionProps) => ({
          bg: "white",
          border: `1px solid ${props.theme.colors.gray[200]}`,
          color: "gray.500",
          fontWeight: "medium",
          fontSize: "sm",
          _hover: {
            bg: "light_gray.200",
            borderColor: "light_gray.200",
          },
          _active: {
            bg: "light_gray.300",
          },
        }),
        primary: {
          fontWeight: "medium",
          fontSize: "sm",
          color: "midnight_teal.400",
          bg: "emerald_mint.50",
          _hover: {
            bg: "emerald_mint.100",
          },
          _active: {
            bg: "emerald_mint.500",
          },
          _disabled: {
            _disabled: {
              _hover: {
                bg: "emerald_mint.50",
              },
            },
          },
        },
        secondary: {
          fontWeight: "normal",
          color: "gray.900",
          bg: "gray.100",
          _hover: {
            bg: "gray.200",
          },
          _active: {
            bg: "gray.300",
          },
        },
      },
    },
    Tabs: {
      variants: {
        line: {
          tab: {
            fontWeight: "medium",
            color: "#6C727F",
            _selected: {
              color: "midnight_teal.400",
              borderBottomWidth: "2px",
              borderBottomColor: "midnight_teal.400",
            },
          },
          tablist: {
            borderBottomWidth: "1px",
            borderBottomColor: "light_gray.300",
          },
        },
      },
    },
  },
});

// eslint-disable-next-line import/no-default-export
export default theme;
