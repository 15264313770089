import React from "react";

import {
  Avatar,
  Box,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useGetUserPermissions } from "network/User/get";
import { FaUser } from "react-icons/fa";
import { ButtonLogout } from "ui/Buttons";
import {
  isAuthorized,
  LinkWithTarget,
  userRoutes,
} from "ui/Nav/Authenticated/Nav";

export const ProfileButton: React.FC<any> = () => {
  // const { first_name } = useUserContext();
  const { data: permissions } = useGetUserPermissions();

  return (
    <Menu>
      <MenuButton aria-label="Options" ml={4}>
        <Avatar
          bg="light_gray.400"
          color="midnight_teal.100"
          boxSize="40px"
          icon={<FaUser size="16px" />}
        />
      </MenuButton>
      <MenuList py={0} zIndex={5000}>
        {userRoutes.map((route) => {
          return (
            isAuthorized(route, permissions) && (
              <LinkWithTarget
                key={route.pathname}
                href={route.pathname}
                openInNewTab={route.openInNewTab}
              >
                <MenuItem
                  p={5}
                  alignItems="center"
                  _hover={{ textDecoration: "underline" }}
                  {...(route.iconSvg
                    ? {
                        icon: (
                          <Icon
                            w={6}
                            h={6}
                            as={route.iconSvg}
                            display="flex"
                            alignItems="center"
                          />
                        ),
                      }
                    : {})}
                >
                  {route.display}
                </MenuItem>
              </LinkWithTarget>
            )
          );
        })}
        <Box p={5} borderTop="1px solid #eee">
          <ButtonLogout w="100%" />
        </Box>
      </MenuList>
    </Menu>
  );
};
